@import "styles/core";

.container {
    position: relative;
}

.section {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: size(20);
    line-height: 1.75;
    padding: size(50) 0 size(40);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(xl) {
        padding: size(15) 0;
        font-size: size(28);
    }
}
