@import "styles/core";

.article {
    font-size: size(13);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.title {
    font-size: size(15);
}

.content {
    flex: 1;
}

.footer {
    color: theme-color("primary");
    opacity: 0.67;
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    justify-self: flex-end;
}
