@import "styles/core";

.card {
    border-radius: 0;
    border-left: 0;
    border-right: 0;

    &:not(:first-child) {
        border-top: 0;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.icon {
    transform: rotate(0deg);
    transition: all 0.3s ease;

    &.rotate {
        transform: rotate(90deg);
    }
}

.clickable {
    cursor: pointer;
}

.collapse {
    background-color: gray("200");
}
