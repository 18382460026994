@import "styles/core";

.card {
    &:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
    }
    &:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.clickable {
    cursor: pointer;
}

.collapse {
    background-color: gray("100");
}

.icon {
    transform: rotate(0deg);
    transition: all 0.3s ease;

    &.rotate {
        transform: rotate(90deg);
    }
}
