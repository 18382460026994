@import "styles/core";
.thumbnailContainer {
    display: inline-block;
    position: relative;
}

.thumbnail {
    width: 200px;
    max-width: 100%;
}

.deleteIcon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
}
