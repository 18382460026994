@import "styles/core";

.schematyzmInfo {
    ol {
        padding-inline-start: size(15);
    }
    li {
        margin: size(15) 0;
    }

    p {
        margin: size(5) 0;
    }
}
