@import "styles/core";

.navbar {
    font-size: size(23);
    line-height: size(37);
    padding-top: size(10);
    padding-bottom: size(10);
    text-align: right;

    @include media-breakpoint-up(md) {
        text-align: left;
    }
}

.logo {
    position: relative;
    width: auto;
    max-width: 100%;
    max-height: size(57);
}

.menu {
    margin: 0 auto;
}

.link {
    color: $primary;
    font-size: inherit;
    line-height: inherit;
    padding: 0 size(15);

    &.active {
        text-decoration: underline;
    }
}

.drawer {
    top: 0;
    font-size: size(18);
    z-index: $zindex-modal;
    user-select: none;

    .link {
        display: inline-block;
        width: 100%;
        margin-bottom: size(15);
        text-align: left;
    }
}

.hamburger {
    line-height: 1;
    margin: 0 0 0 auto;
}

.icon {
    font-size: 5.75rem;
    width: 80%;
    max-width: size(120);
    height: auto;
    display: block;
    color: #33384c;
}
