@import "styles/core";

.button {
    position: relative;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    margin-left: -1em;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    color: inherit;
}

.content {
    &:not(:last-child) {
        opacity: 0;
        visibility: hidden;
    }
}
