/**
 * COLORS
 */
$black: #33384c !default;
$white: #fff !default;
$primary: #33384c !default;
$secondary: #234a9e !default;
$green: #005e09 !default;
$yellow: #faeb02 !default;
$body-color: $black !default;

$theme-colors: (
    "black": $black,
    "white": $white,
    "green": $green,
) !default;

$colors: map-merge((), $theme-colors) !default;

/**
 * GRID
 */
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px // inner content will be 1170px wide,,,,,,,,,,,,,,,,,,,
) !default;

/**
 * FONT FAMILIES
 */
$font-family-primary: "Cairo", sans-serif;
$font-family-secondary: "Montserrat", sans-serif;
$font-family-sans-serif: $font-family-primary !default;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-secondary !default;
$input-btn-font-family: $font-family-primary;

/**
 * FONT SIZES
 */
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 1.625 !default;
$h3-font-size: $font-size-base * 0.75 !default;
$h4-font-size: $font-size-base * 1.75 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base * 1.25 !default;

/**
 * FONT WEIGHTS
 */
$font-weight-medium: 500;
$font-weight-bold: 700;
$btn-font-weight: $font-weight-medium !default;

/**
 * MISC
 */

$border-radius-lg: size(10);

/**
 * BUTTONS
 */
$input-btn-padding-y: 0.5625rem !default;

/**
 * CARDS
 */
$card-spacer-x: size(20);
$card-spacer-y: size(25);
$card-border-radius: size(10);
// $card-border-width: 0;
$card-bg: $white;

/**
 * TRANSLATIONS
*/
$custom-file-text: (
    en: "Browse",
    es: "Elegir",
    pl: "Wybierz plik",
);

@import "~bootstrap/scss/variables";
