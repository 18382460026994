@import "styles/core";

.group {
    position: relative;
}

.clear {
    position: absolute;
    top: #{$input-height-inner-half};
    right: #{$input-padding-x / 2};
    transform: translateY(-50%);
    cursor: pointer;
}
