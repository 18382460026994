@import "styles/core";

.footer {
    a {
        color: theme-color("white");

        &:hover {
            color: theme-color("white");
            text-decoration: underline;
        }
    }
}

.col {
    font-size: smaller;
    font-weight: $font-weight-light;

    p {
        margin-bottom: $spacer;
    }
}

.wiecej {
    text-align: right;
}

.baner {
    position: relative;
    width: auto;
    max-width: 100%;
    max-height: size(96);
}
