@import "styles/core";

.card {
    border-radius: 0;
    border-left: 0;
    border-right: 0;

    &:not(:first-child) {
        border-top: 0;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.info {
    font-size: smaller;
}

.status {
    font-size: size(11);
}
